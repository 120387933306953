<template lang="html">
  <div class="logos" v-if="!isMobile && initialData.logos">
    <div class="logos__heading" v-if="initialData.heading">
      <Heading :text="initialData.heading" :hType="2" />
    </div>
    <div class="logos__items">
      <div class="logos__item" v-for="(logo, i) in initialData.logos" :key="i">
        <a
          v-if="logo.link"
          class="logos__item-logo"
          :href="logo.link.url"
          :target="logo.link.target"
        >
          <img v-if="logo.logo" :src="logo.logo.url" :alt="logo.logo.alt" />
        </a>
        <div v-else class="logos__item-logo">
          <img v-if="logo.logo" :src="logo.logo.url" :alt="logo.logo.alt" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from '@/components/Heading/Heading.vue';

export default {
  name: 'Logos',
  components: { Heading },
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isMobile() {
      return this.$store.state.global.isMobile;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
